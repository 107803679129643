import Home from './pages/Home';
import TrackOrder from './pages/TrackOrder'
import { Route, Routes, HashRouter } from "react-router-dom";
import './App.css'

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/track/:id' element={<TrackOrder />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
