import React, { useState, useEffect } from "react";
import axios from 'axios'
import Maps from "./maps";
import { useLoadScript } from "@react-google-maps/api";
import { useParams } from 'react-router-dom';

import Header from '../../components/Header'
import './styles.css';
import Sidebar from "./Sidebar";


const TrackOrder = () => {
  const params = useParams();
  const [bottomOpen, setBottomOpen] = useState(true);
  const [inactive, setInactive] = useState(false);
  const [data, setData] = useState(null);


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
  });

  const isMobile = window.innerWidth <= 600;

  const fetchData = async () => {
    try {
      const getOrderStatus = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/shipping/shippment/tracking-details/${params?.id}`);
      if(getOrderStatus.data?.success === true){
        setData(getOrderStatus?.data?.data)
      }
      
    } catch (error) {
      // alert(error.response.data.error)
    }
  };

  useEffect(() => {
    // Call fetchData() initially to fetch data
    fetchData();
  }, [params?.id])

  console.log("inactive", inactive)

  return (
    <div style={{ position: "relative" }}>
      {
        !isMobile && <Header tracker={true} />
      }
      <div style={{ background: "#F3F6FA" }}>
        {isLoaded && data ? (
          <div className={`maps__wrapper ${inactive ? "map-inactive" : ""}`}>
            {/* <Maps driver={data?.driver} bottomOpen={bottomOpen} center={{lat:data?.coordinates[0] , lng: data?.coordinates[1]}} address={{lat: data?.coordinates[0], lng: data?.coordinates[1]}} home={{lat: data?.homeCoordinates[0] ? data?.homeCoordinates[0] : 0, lng: data?.homeCoordinates[1] ? data?.homeCoordinates : 0}} /> */}
            <Maps riderId={data?.riderId} shipmentId={data?.shipmentDetail?._id}/>
          </div>
        ) : null}
      </div>
      <Sidebar orderId={params?.id} bottomOpen={bottomOpen} setBottomOpen={setBottomOpen} inactive={inactive} setInactive={setInactive}/>
    </div>
  );
};

export default TrackOrder;
