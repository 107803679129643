import React from 'react';
import logo from '../../images/logo.svg';
import './styles.css';

const Header = ({ tracker }) => {
  return (
    <div className={`header ${tracker ? 'tracker': ''}`}>
        <img src={logo} alt="logo" />
        <h2>Go Trucking</h2>
    </div>
  )
}

export default Header