import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerF, Polyline } from "@react-google-maps/api";



import Car from '../../images/car.svg';


function Map({ bottomOpen, center, address, home, driver, riderId, shipmentId }) {

  const [driverLocation, setDriverLocation] = useState([]);
  const [path, setPath] = useState([]);
  const [location, setLocation] = useState({lat: -33.9073383, lng: 151.1247624})

  const containerHeight = window.innerWidth <= 600 && !bottomOpen ? "75vh" : "calc(100vh - 115px)";


  useEffect(() => {
    

    const ws = new WebSocket( `${process.env.REACT_APP_WSS}`); // Replace with your WebSocket server URL


    ws.onopen = (event) => {

      // Send the Redis channel name to the server
      const channelName = `${riderId}-${shipmentId}`; // Replace with your channel name
      ws.send(channelName);
    };

    ws.onmessage = (event) => {
  

      const data = JSON.parse(event.data);


      setDriverLocation(data);

      const Polyline = data?.map((item) => ({
        lat: item?.lat,
        lng: item?.lng
      }));

      setPath(Polyline)
      
    };

    // Cleanup the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, [riderId, shipmentId]);

  console.log("path", path)

  useEffect(() => {
   const centerMap = driverLocation[0] ? {lat: driverLocation[driverLocation?.length - 1]?.lat, lng: driverLocation[driverLocation?.length - 1]?.lng} : {lat: -33.9073383, lng: 151.1247624};
    setLocation(centerMap)
  }, [driverLocation, address])


  const renderMarkers = () =>  {

    const markers = [];

    if(driverLocation?.length > 0){
      markers.push(
        <MarkerF
          key={driverLocation[0]?.lat}
          position={{ lat: driverLocation[driverLocation?.length - 1]?.lat, lng: driverLocation[driverLocation?.length - 1]?.lng }}
          title='Driver Live Locations'
          icon={Car}
        />
      )

    }

    return markers;
  }

  const mapOptions = {
    gestureHandling: "greedy",
    zoom: 16,
    center: location
  };


  return (
    <GoogleMap
      onLoad={map => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
      }}

      center={location}
      mapContainerStyle={{ width: "100%", height: containerHeight}}
      zoom={18}
      options={mapOptions}
      
    >

        <Polyline
          path={path}
          options={{
            strokeColor: 'green',
            strokeOpacity: 1.0,
            strokeWeight: 4,
          }}
          />
          {renderMarkers()}
    </GoogleMap>
  );
}

export default React.memo(Map);
