import React, { useState, useEffect } from 'react'
import axios from "axios";
import moment from "moment"
import { useNavigate } from 'react-router-dom';


import './styles.css'
import hamburger from '../../images/hamburger.svg';
import search from '../../images/search.svg';
import line1 from '../../images/line1.svg';
import line2 from '../../images/line2.svg';
import arrow_down from '../../images/arrow-down.svg';
import horizontal_1 from '../../images/horizontal_1.svg';
import horizontal_2 from '../../images/horizontal_2.svg';

import PreparingOrderWhite from "../../images/PreparingOrderWhite.svg"
import PickedUpWhite from "../../images/PickedUpWhite.svg"
import DeliveredWhite from "../../images/DeliveredWhite.svg"


import PreparingOrderPink from "../../images/PreparingOrderPink.svg"
import PickedUpPink from "../../images/PickedUpPink.svg"
import DeliveredPink from "../../images/DeliveredPink.svg"


const Sidebar = ({ orderId, bottomOpen, setBottomOpen, inactive, setInactive}) => {
  
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate(`/`)
  }

  

  const fetchData = async () => {
    try {
      const getOrderStatus = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/shipping/shippment/tracking-details/${orderId}`);
      if(getOrderStatus.data?.success === true){
        setData(getOrderStatus?.data?.data?.shipmentDetail)
      }
      
    } catch (error) {
      // alert(error.response.data.error)
    }
  };

  useEffect(() => {
    // Call fetchData() initially to fetch data
    fetchData();

    // Call fetchData() every minute (60,000 milliseconds)
    const intervalId = setInterval(fetchData, 30000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);


  return (
    <>
      <div className={`side__menu ${inactive ? "inactive" : ""}`}>
        <div className="menu__header">
          <div className="flex__center menu__header__left">
            {/* <img src={logo} alt="logo" /> */}
            <h3 className="sidebar__heading">Go Trucking Tracker</h3>
          </div>
          <img src={hamburger} alt="hamburger" className="hamburger" onClick={() => setInactive(!inactive)} />
        </div>
        <MenuContent inactive={inactive} navigateToHome={navigateToHome} data={data} orderId={orderId} address={data?.address} createdTime={data?.createdTime} pickedTime={data?.pickedTime} deliveryTime={data?.deliveryTime} status={data?.status}/>
      </div>
      <div className={`mobile__bottom__menu ${!bottomOpen ? "mobile__inactive" : ""}`} onClick={() => !bottomOpen && setBottomOpen(!bottomOpen)} >
        {
          bottomOpen ? (
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <img src={arrow_down} alt="" onClick={() => setBottomOpen(!bottomOpen)} />
              </div>
              <MenuContent inactive={inactive} navigateToHome={navigateToHome} data={data} orderId={orderId} address={data?.address} createdTime={data?.createdTime} pickedTime={data?.pickedTime} deliveryTime={data?.deliveryTime} status={data?.status}/>
            </div>
          ) : (
            <div className='flex__between'>
              <div className='mb__menu__card'>
                <div className="relative">
                {
              data?.checkingTime  ? (

                <img style={{width: '50px', height: '50px'}} src={PreparingOrderPink} alt="prepare-order"  />
              ) : (
                <img style={{width: '50px', height: '50px'}} src={PreparingOrderWhite} alt="prepare-order"  />
              )
            }
                  <img src={horizontal_1} alt="" className='x__line' />
                </div>
                <p>Checking Time</p>
              </div>
              <div className='mb__menu__card'>
                <div className="relative">
                {
              data?.startTime  ? (

                <img style={{width: '50px', height: '50px'}} src={PickedUpPink} alt="order-start" />
              ) : (
                <img style={{width: '50px', height: '50px'}} src={PickedUpWhite} alt="order-start" />
              )
            }
                  <img src={horizontal_2} alt="" className='x__line' />
                </div>
                <p>In Transit Time</p>
              </div>
              <div className='mb__menu__card'>
              <div className="relative">
              {
              data?.deliveringTime ? (

                <img style={{width: '50px', height: '50px'}} src={DeliveredPink} alt="deliverd-order" />
              ) : (
                <img style={{width: '50px', height: '50px'}} src={DeliveredWhite} alt="deliverd-order" />
              )
            }
            <img src={horizontal_2} alt="" className='x__line' />
                </div>
                <p>Out For Delivery</p>
              </div>
              <div className='mb__menu__card'>
              {
              data?.deliveredTime ? (

                <img style={{width: '50px', height: '50px'}} src={DeliveredPink} alt="deliverd-order" />
              ) : (
                <img style={{width: '50px', height: '50px'}} src={DeliveredWhite} alt="deliverd-order" />
              )
            }
                <p>Delivered Time</p>
              </div>
            </div>
          )
        }
      </div>
    </>
  )
}

const MenuContent = ({ inactive, navigateToHome, data, orderId, address, createdTime, pickedTime, deliveryTime, status }) => {

  const calculateTimeDifference = (checkingTime, deliveredTime) => {
    // Parse the API times into Date objects
    const checkingTimeDate = new Date(checkingTime);
    const userCurrentTimeDate = new Date();
    const deliveredTimeDate = deliveredTime ? new Date(deliveredTime) : null;
  
    // Determine which time to use (userCurrentTime or deliveredTime)
    const timeToUse = deliveredTimeDate || userCurrentTimeDate;
  
    // Calculate the time difference in milliseconds
    const timeDifferenceMillis = timeToUse - checkingTimeDate;
  
    // Convert milliseconds to hours
    const timeDifferenceHours = timeDifferenceMillis / (1000 * 60 * 60);
  
    return timeDifferenceHours.toFixed(2);
  }

  function calculateEstimatedArrivalTime(checkingTime, totalTime) {
    // Parse the checkingTime into a Moment.js object
    const checkingTimeMoment = moment(checkingTime);
  
    // Calculate the estimated arrival time by adding totalTime in hours
    const estimatedArrivalTime = checkingTimeMoment.add(totalTime, 'hours');
  
    // Format the estimated arrival time as a string in your desired format
    const formattedEstimatedArrivalTime = estimatedArrivalTime.format("YYYY-MM-DD HH:mm:ss");
  
    return formattedEstimatedArrivalTime;
  }


  return (
    <>
      <div className="search__wrap">
        <span className="search__icon" onClick={navigateToHome}>
        <img src={search} alt="search-icon" />
        </span>
        <div className="search__id">
          <p>Order ID/Number</p>
          <h3>{orderId}</h3>
        </div>

      </div>
      {
        data && !inactive ? (

      <div className="search__wrap_2">
        <div className="search_title">
          <h3>Details</h3>
          <p><span>Estimated Total Distance:</span> {data?.totalDistance} km</p>

          <p><span>Estimated Total Time:</span> {data?.estimationTime} hours</p>

          <p><span>Estimated Time Used: </span> {calculateTimeDifference(data?.checkingTime, data?.deliveredTime)} hours</p>

    
          {
            data?.deliveredTime ? "" : (
          <p><span>Estimated Arrival Time: </span> 
          {calculateEstimatedArrivalTime(data?.checkingTime, data?.estimationTime)}</p>
              
            )
          }
        </div>
        </div>
        ) : ""
      }
      <div className="tracker__wrap">
        <div className="track__one track">
          <div className="relative">
            {
              data?.checkingTime ? (

                <img style={{width: '50px', height: '50px'}} src={PreparingOrderPink} alt="prepare-order"  />
              ) : (
                <img style={{width: '50px', height: '50px'}} src={PreparingOrderWhite} alt="prepare-order"  />
              )
            }
            <img src={line1} alt="" className="line__one" />
          </div>
          <div className="track__one__details">
            <h3>Checking Time</h3>
           { data?.checkingTime ? (
                <>
                <p>{moment(data?.checkingTime).format('DD MMM, YYYY')}</p>
                 <p>{moment(data?.checkingTime).utc().format('hh : mm a')}</p>
                </>
              ): "-"}
          </div>
        </div>
        <div className="track__one track">
          <div className="relative">
            {
              data?.startTime ? (

                <img style={{width: '50px', height: '50px'}} src={PickedUpPink} alt="order-start" />
              ) : (
                <img style={{width: '50px', height: '50px'}} src={PickedUpWhite} alt="order-start" />
              )
            }
            <img src={line2} alt="" className="line__one" />
          </div>
          <div className="track__two__details">
            <h3>In Transit Time</h3>
            {
              data?.startTime ? (
                <>
                <p>{moment(data?.startTime).format('DD MMM, YYYY')}</p>
                 <p>{moment(data?.startTime).utc().format('hh : mm a')}</p>
                </>
              ): "-"
            }
            
          </div>
        </div>
        <div className="track__one track">
        <div className="relative">
        {
              data?.deliveringTime ? (

                <img style={{width: '50px', height: '50px'}} src={DeliveredPink} alt="deliverd-order" />
              ) : (
                <img style={{width: '50px', height: '50px'}} src={DeliveredWhite} alt="deliverd-order" />
              )
            }
            <img src={line2} alt="" className="line__one" />
          </div>
          <div className="track__three__details">
            <h3>Out For Delivery</h3>
            {
              data?.deliveringTime ? (
                <>
                <p>{moment(data?.deliveringTime).format('DD MMM, YYYY')}</p>
                 <p>{moment(data?.deliveringTime).utc().format('hh : mm a')}</p>
                </>
              ): "-"
            }
            <p>{address}</p>
          </div>
          
        </div>
        <div className="track__one track">
        {
              data?.deliveredTime ? (

                <img style={{width: '50px', height: '50px'}} src={DeliveredPink} alt="deliverd-order" />
              ) : (
                <img style={{width: '50px', height: '50px'}} src={DeliveredWhite} alt="deliverd-order" />
              )
            }
          <div className="track__three__details">
            <h3>Delivered Time</h3>
            {
              data?.deliveredTime ? (
                <>
                <p>{moment(data?.deliveredTime).format('DD MMM, YYYY')}</p>
                 <p>{moment(data?.deliveredTime).utc().format('hh : mm a')}</p>
                </>
              ): "-"
            }
            <p>{address}</p>
          </div>
          
        </div>
      </div>
      <p className="menu__footer">If you have any queries regarding your parcel, please contact us.</p>
    </>
  )
}

export default Sidebar